

































































import { Component, Ref } from 'vue-property-decorator';
import TransformComponent from '@common-src/mixins/transform-component';
import UserService from '@common-src/service/user-v2';
import OrgService from '@common-src/service/org';
@Component
export default class LinkDepartmentDialog extends TransformComponent {
    roleId: string = null;
    orgTreeData: Array<any> = null;
    userService: UserService = new UserService();
    orgIdsSearch: Array<string> = null;
    userNameSearch: string = null;
    selectedKeys: string[] = ['328d09d7-d152-43ed-a5b8-3d6ca96f81dd'];
    checkedKeys: { checked: Array<any>, halfChecked?: Array<any> } = null;
    created() {
        OrgService.getOrgTree().then(res => {
            this.orgTreeData = res;
            this.orgTreeData.shift();
        });
    }

    dialogOpen(roleId: string) {
        this.roleId = roleId;
        this.dialogVisible = true;
        this.rightDataList = [];
        this.listData = [];
        this.checkedKeys = { checked: [] };
        this.userService.getLinkDepartement(this.roleId).then(res => {
            this.checkedKeys = { checked: _.map(res, el => el.id) };
            this.rightDataList = _.map(res, item => {
                return {
                    id: item.id,
                    name: item.name
                };
            });
        }).catch(err => {
            this.dialogVisible = false;
        });
    }

    removeAllClick() {
        this.rightDataList = [];
        this.checkedKeys = { checked: [] };
    }

    removeItemClick(model, index) {
        const crrentIds = _.filter(this.checkedKeys.checked, item => item !== model.id);
        this.checkedKeys = { checked: crrentIds };
        this.rightDataList.splice(index, 1);
    }

    onCheck(checkedKeys, e:{checked: boolean, checkedNodes, node, event}) {
        if (e.checked) {
            this.rightDataList.push({ id: e.node.dataRef.id, name: e.node.dataRef.getCascadeName() });
        } else {
            const index = _.findIndex(this.rightDataList, item => item.id === e.node.dataRef.id);
            if (index > -1) {
                this.rightDataList.splice(index, 1);
            }
        }
    }
    dialogOK() {
        return this.userService.saveLinkDepartment(this.roleId, _.map(this.rightDataList, item => item.id)).then((ret) => {
            this.dialogClose();
            this.$emit('dialogOK');
        });
    }

    dialogClose(): void {
        this.listData = null;
        this.rightDataList = [];
        this.userNameSearch = null;
        this.orgIdsSearch = [];
        this.dialogVisible = false;
    }
}
