























































import { Component } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import { PermissionEntityModel } from '@common-src/entity-model/permission-entity';
import RoleService from '@common-src/service/role-v2';

@Component
export default class RoleTopoComponent extends DrawerComponent {
    permissionList: Array<PermissionEntityModel> = null;
    modelId: string;
    loading: boolean = true;

    drawerRoleOpen(id: string) {
        this.modelId = id;
        this.drawerVisible = true;
        this.init();
    }

    init() {
        this.loading = true;
        RoleService.getTopoPermissions(this.modelId)
            .then(res => {
                this.permissionList = res;
            })
            .finally(() => {
                this.loading = false;
            });
    }

    save() {
        // const permissions = _.map(_.filter(_.flatMapDepth(this.permissionList, 'permissions', 1), item => item.select), item => item.id);
        const selectPermissionTree = _.map(_.filter(_.flatMapDepth(this.permissionList, 'children', 1), item => item.select || item.Indeterminate), (item: PermissionEntityModel) => item.toRoleService());
        const permissions = this.getSelectPermissions(selectPermissionTree);
        return RoleService.saveTopoPermission(this.modelId, permissions).then(res => {
            this.showMessageSuccess('保存权限成功');
        }).catch(err => {
            throw err;
        });
    }

    getSelectPermissions(treeData: Array<any>) {
        const permissions = [];
        _.forEach(treeData, item => {
            permissions.push(item.id);
            if (item.children && item.children.length > 0) {
                permissions.push(...this.getSelectPermissions(item.children));
            }
        });
        return permissions;
    }
}
