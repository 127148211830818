






































































import { Component, Ref } from 'vue-property-decorator';
import TransformComponent from '@common-src/mixins/transform-component';
import { GroupEntityType } from '@common-src/model/enum';
import { DeviceTypeEntityModel, DeviceTypeQueryModel } from '@common-src/entity-model/device-type-entity';
import FacilityTypeService from '@common-src/service/facility-type';
import { FormControlType } from '@common-src/model/form-control';
import DeviceTypeService from '@common-src/service/device-type';
import FacilityGroupService from '@common-src/service/facility-group';
import UserService from '@common-src/service/user-v2';
import { UserQueryModel } from '@common-src/entity-model/user-entity';
import OrgService from '@common-src/service/org';
// import RoleExchangeDialog from './role-exchange-dialog.vue';

// @Component({
//     components: {
//         'role-exchange-dialog': RoleExchangeDialog
//     }
// })
@Component
export default class LinkUserDialog extends TransformComponent {
    roleId: string = null;
    orgTreeData: Array<any> = null;
    userService: UserService = new UserService();
    orgIdsSearch: Array<string> = null;
    userNameSearch: string = null;

    created() {
        OrgService.getOrgTree().then(res => {
            this.orgTreeData = res;
        });
    }

    get ListData() {
        const hasNameFilter = !!this.userNameSearch;
        const hasOrgFilter = this.orgIdsSearch && this.orgIdsSearch.length > 0 && this.orgIdsSearch[0];
        let filterData = this.listData;
        if (hasNameFilter) {
            filterData = _.filter(filterData, item => {
                return item.name.indexOf(this.userNameSearch) > -1;
            });
        }
        if (hasOrgFilter) {
            filterData = _.filter(filterData, item => {
                return this.orgIdsSearch.indexOf(item.orgId) > -1;
            });
        }
        return filterData;
    }

    dialogOpen(roleId: string) {
        this.roleId = roleId;
        this.dialogVisible = true;
        this.rightDataList = [];
        this.listData = [];
        this.userService.getunLinkUsers(this.roleId).then(res => {
            this.listData = _.map(res, item => {
                return {
                    id: item.id,
                    name: item.name,
                    orgId: item.orgId,
                    // roleId: item.roleId,
                    check: item.roleIds?.indexOf(roleId) > -1
                };
            });
            this.rightDataList = _.filter(_.map(res, item => {
                return {
                    id: item.id,
                    name: item.name,
                    check: item.roleIds?.indexOf(roleId) > -1
                };
            }), item => item.check);
        }).catch(err => {
            this.dialogVisible = false;
        });
    }

    // exchangeClick(model) {
    //     (this.$refs.roleExchangeDialog as any).dialogOpen(this.listData[1]);
    // }

    dialogOK() {
        return this.userService.saveLinkUser(this.roleId, _.map(this.rightDataList, item => item.id)).then((ret) => {
            this.dialogClose();
            this.$emit('dialogOK');
        });
    }

    dialogClose(): void {
        this.listData = null;
        this.rightDataList = [];
        this.userNameSearch = null;
        this.orgIdsSearch = [];
        this.dialogVisible = false;
    }
}
