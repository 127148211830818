var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.drawerTitle || "分配权限",
        placement: _vm.placement,
        width: _vm.width,
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true,
      },
      on: { close: _vm.drawerClose },
    },
    [
      _c(
        "div",
        { staticClass: "role-topo-drawer-component" },
        [
          _c(
            "a-spin",
            { attrs: { spinning: _vm.loading } },
            [
              _c(
                "a-tabs",
                { attrs: { type: "card" } },
                _vm._l(_vm.permissionList, function (group) {
                  return _c(
                    "a-tab-pane",
                    {
                      key: group.name,
                      attrs: { "force-render": "", tab: group.name },
                    },
                    _vm._l(group.children, function (menu) {
                      return _c(
                        "div",
                        { key: menu.id, staticClass: "menu-item-wrapper" },
                        [
                          _c(
                            "div",
                            { staticClass: "menu-item-header" },
                            [
                              _c(
                                "a-checkbox",
                                {
                                  attrs: { indeterminate: menu.Indeterminate },
                                  model: {
                                    value: menu.AllSelect,
                                    callback: function ($$v) {
                                      _vm.$set(menu, "AllSelect", $$v)
                                    },
                                    expression: "menu.AllSelect",
                                  },
                                },
                                [_vm._v(_vm._s(menu.name))]
                              ),
                            ],
                            1
                          ),
                          menu.HasChildren
                            ? _c(
                                "a-descriptions",
                                { attrs: { bordered: "", column: 1 } },
                                [
                                  _vm._l(menu.children, function (page) {
                                    return [
                                      _c(
                                        "a-descriptions-item",
                                        { key: page.id },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "label" },
                                              slot: "label",
                                            },
                                            [
                                              _c(
                                                "a-checkbox",
                                                {
                                                  attrs: {
                                                    indeterminate:
                                                      page.Indeterminate,
                                                  },
                                                  model: {
                                                    value: page.AllSelect,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        page,
                                                        "AllSelect",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "page.AllSelect",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(page.name) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          page.children &&
                                          page.children.length > 0
                                            ? _c(
                                                "a-descriptions",
                                                {
                                                  attrs: {
                                                    bordered: "",
                                                    column: 1,
                                                  },
                                                },
                                                [
                                                  _vm._l(
                                                    page.children,
                                                    function (subPage) {
                                                      return [
                                                        _c(
                                                          "a-descriptions-item",
                                                          { key: subPage.id },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                attrs: {
                                                                  slot: "label",
                                                                },
                                                                slot: "label",
                                                              },
                                                              [
                                                                _c(
                                                                  "a-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      indeterminate:
                                                                        subPage.Indeterminate,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        subPage.AllSelect,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            subPage,
                                                                            "AllSelect",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "subPage.AllSelect",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          subPage.name
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._l(
                                                              subPage.children,
                                                              function (func) {
                                                                return [
                                                                  _c(
                                                                    "a-checkbox",
                                                                    {
                                                                      key: func.id,
                                                                      model: {
                                                                        value:
                                                                          func.select,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              func,
                                                                              "select",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "func.select",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            func.name
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  )
                }),
                1
              ),
              _c("br"),
              _c(
                "jtl-button",
                {
                  staticClass: "save-button",
                  attrs: { type: "primary", "click-prop": _vm.save.bind(this) },
                },
                [_vm._v("保存权限")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }